<!-- Start Woman Banner Area -->
<div class="uk-woman-banner woman-main-banner-area">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="uk-item uk-flex-middle">
                <div class="woman-banner-content">
                    <span>Marketing</span>
                    <h1>We Are Marketing Expert Agency</h1>
                    <p>Pellentesque in ipsum id orci porta dapibus. vivamus suscipit tortor eget felis porttitor volutpat. cras ultricies ligula sed magna dictum porta.</p>

                    <ul class="banner-btn-list">
                        <li>
                            <a href="#" class="uk-button uk-button-default">Get Started</a>
                        </li>
                        <li>
                            <a href="#" class="uk-button uk-button-default">About Us</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="uk-item">
                <div class="woman-banner-image">
                    <img src="assets/img/home-seven/banner/wonan.png" alt="image">
                </div>
            </div>
        </div>
    </div>

    <ul class="woman-banner-social">
        <li>
            <span>Say Hello</span>
        </li>
        <li><a href="#"><i class="flaticon-logo"></i></a></li>
        <li><a href="#"><i class="flaticon-twitter"></i></a></li>
        <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
    </ul>
</div>
<!-- End Woman Banner Area -->

<!-- Start Creative About Area -->
<div class="uk-creative-about creative-about-area ptb-100">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="uk-item">
                <div class="creative-about-left-content">
                    <div class="big-text">About Us</div>
                    <span>About Us</span>
                    <h3>Leading The Way In Creative Marketing Agency</h3>
                    <p>We provide marketing services to startups and small businesses to looking for a partner of their digital media, design & development, lead generation and communications requirents. We work with you, not for you. Although we have a great resources.</p>

                    <div class="about-left-image">
                        <img src="assets/img/home-seven/about/about1.jpg" alt="image">

                        <div class="content">
                            <h4>15</h4>
                            <b>Years Experience</b>
                        </div>
                    </div>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-about-right-content">
                    <div class="about-right-image">
                        <img src="assets/img/home-seven/about/about2.jpg" alt="image">

                        <div class="content">
                            <h4>99%</h4>
                            <b>Projects Done</b>
                        </div>
                    </div>
                    <h3>We Are Marketing Expert You Need For Your Business</h3>
                    <p>We provide marketing services to startups and small businesses to looking for a partner of their digital media, design & development, lead generation and communications requirents. We work with you, not for you. Although we have a great resources.</p>
                    <div class="about-right-btn">
                        <a href="#" class="uk-button uk-button-default">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Creative About Area -->

<!-- Start Creative Services Area -->
<div class="uk-creative-services creative-services-area pt-100 pb-70">
    <div class="uk-container">
        <div class="section-title-with-big-text">
            <div class="big-text">What We Do</div>
            <span>OUR SERVICES</span>
            <h2>All The Services That We Provide To Meet The Business Needs Of The Clients</h2>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            <div class="uk-item">
                <div class="creative-services-card">
                    <div class="number">01</div>
                    <h3>
                        <a href="single-services.html">Marketing Solutions</a>
                    </h3>
                    <p>Praesent sapien massa convallis a pellentesque nec egestas non nisi mauris blandit aliquet elit eget.</p>
                    <a href="single-services.html" class="services-btn">Read More <i class="flaticon-right"></i></a>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-services-card">
                    <div class="number two">02</div>
                    <h3>
                        <a href="single-services.html">Mobile Applications</a>
                    </h3>
                    <p>Praesent sapien massa convallis a pellentesque nec egestas non nisi mauris blandit aliquet elit eget.</p>
                    <a href="single-services.html" class="services-btn">Read More <i class="flaticon-right"></i></a>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-services-card">
                    <div class="number three">03</div>
                    <h3>
                        <a href="single-services.html">Web Development</a>
                    </h3>
                    <p>Praesent sapien massa convallis a pellentesque nec egestas non nisi mauris blandit aliquet elit eget.</p>
                    <a href="single-services.html" class="services-btn">Read More <i class="flaticon-right"></i></a>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-services-card">
                    <div class="number four">04</div>
                    <h3>
                        <a href="single-services.html">E-commerce</a>
                    </h3>
                    <p>Praesent sapien massa convallis a pellentesque nec egestas non nisi mauris blandit aliquet elit eget.</p>
                    <a href="single-services.html" class="services-btn">Read More <i class="flaticon-right"></i></a>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-services-card">
                    <div class="number five">05</div>
                    <h3>
                        <a href="single-services.html">Business Analytics</a>
                    </h3>
                    <p>Praesent sapien massa convallis a pellentesque nec egestas non nisi mauris blandit aliquet elit eget.</p>
                    <a href="single-services.html" class="services-btn">Read More <i class="flaticon-right"></i></a>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-services-card">
                    <div class="number six">06</div>
                    <h3>
                        <a href="single-services.html">Creative Solutions</a>
                    </h3>
                    <p>Praesent sapien massa convallis a pellentesque nec egestas non nisi mauris blandit aliquet elit eget.</p>
                    <a href="single-services.html" class="services-btn">Read More <i class="flaticon-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Creative Services Area -->

<!-- Start Creative Projects Area -->
<div class="uk-creative-projects creative-projects-area ptb-100">
    <div class="uk-container">
        <div class="section-title-with-big-text">
            <div class="big-text">Projects</div>
            <span>OUR COMPLETED PROJECTS</span>
            <h2>Take A Look At All The Projects That We Have Completed For The Client</h2>
        </div>
    </div>
    
    <div class="uk-container-expand">
        <div class="creative-projects-slides owl-carousel owl-theme">
            <div class="creative-single-projects">
                <div class="projects-image">
                    <a href="single-project.html">
                        <img src="assets/img/home-seven/projects/projects1.jpg" alt="image">
                    </a>
                </div>
                <div class="projects-content">
                    <span>Marketing</span>
                    <h3>
                        <a href="single-project.html">Affiliate Marketing</a>
                    </h3>
                </div>
                <div class="projects-icon">
                    <a href="single-project.html"><i class="flaticon-right"></i></a>
                </div>
            </div>

            <div class="creative-single-projects">
                <div class="projects-image">
                    <a href="single-project.html">
                        <img src="assets/img/home-seven/projects/projects2.jpg" alt="image">
                    </a>
                </div>
                <div class="projects-content">
                    <span>Mobile App</span>
                    <h3>
                        <a href="single-project.html">Mobile Applications</a>
                    </h3>
                </div>
                <div class="projects-icon">
                    <a href="single-project.html"><i class="flaticon-right"></i></a>
                </div>
            </div>

            <div class="creative-single-projects">
                <div class="projects-image">
                    <a href="single-project.html">
                        <img src="assets/img/home-seven/projects/projects3.jpg" alt="image">
                    </a>
                </div>
                <div class="projects-content">
                    <span>Hosting</span>
                    <h3>
                        <a href="single-project.html">Web Hosting</a>
                    </h3>
                </div>
                <div class="projects-icon">
                    <a href="single-project.html"><i class="flaticon-right"></i></a>
                </div>
            </div>

            <div class="creative-single-projects">
                <div class="projects-image">
                    <a href="single-project.html">
                        <img src="assets/img/home-seven/projects/projects4.jpg" alt="image">
                    </a>
                </div>
                <div class="projects-content">
                    <span>Development</span>
                    <h3>
                        <a href="single-project.html">Web Development</a>
                    </h3>
                </div>
                <div class="projects-icon">
                    <a href="single-project.html"><i class="flaticon-right"></i></a>
                </div>
            </div>

            <div class="creative-single-projects">
                <div class="projects-image">
                    <a href="single-project.html">
                        <img src="assets/img/home-seven/projects/projects5.jpg" alt="image">
                    </a>
                </div>
                <div class="projects-content">
                    <span>Bridge</span>
                    <h3>
                        <a href="single-project.html">E-commerce</a>
                    </h3>
                </div>
                <div class="projects-icon">
                    <a href="single-project.html"><i class="flaticon-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Creative Projects Area -->

<!-- Start Creative Reviews Area -->
<div class="uk-creative-reviews creative-reviews-area pb-100">
    <div class="uk-container">
        <div class="section-title-with-big-text">
            <div class="big-text">Reviews</div>
            <span>Testimonials</span>
            <h2>What Client Says About Us</h2>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="creative-reviews-img">
                    <img src="assets/img/home-seven/reviews.jpg" alt="image">

                    <div class="icon">
                        <i class="flaticon-quote"></i>
                    </div>
                </div>
            </div>

            <div class="item uk-flex-middle">
                <div class="creative-reviews-slides owl-carousel owl-theme">
                    <div class="creative-reviews-card">
                        <ul class="rating">
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                        </ul>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                        <div class="client">
                            <h3>Jason Statham</h3>
                            <span>Founder at Envato</span>
                        </div>
                    </div>

                    <div class="creative-reviews-card">
                        <ul class="rating">
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                        </ul>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                        <div class="client">
                            <h3>Jason Statham</h3>
                            <span>Founder at Envato</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Creative Reviews Area -->

<!-- Start Creative Partner Area -->
<div class="uk-creative-partner creative-partner-area ptb-100">
    <div class="uk-container">
        <div class="section-title-with-big-text">
            <div class="big-text">Clients</div>
            <span>Valuable Clients</span>
            <h2>See Our Industry Valuable Clients</h2>
        </div>

        <div class="creative-partner-slides owl-carousel owl-theme">
            <div class="creative-partner-item">
                <a href="#">
                    <img src="assets/img/home-seven/partner/partner1.png" alt="image">
                </a>
            </div>

            <div class="creative-partner-item">
                <a href="#">
                    <img src="assets/img/home-seven/partner/partner2.png" alt="image">
                </a>
            </div>

            <div class="creative-partner-item">
                <a href="#">
                    <img src="assets/img/home-seven/partner/partner3.png" alt="image">
                </a>
            </div>

            <div class="creative-partner-item">
                <a href="#">
                    <img src="assets/img/home-seven/partner/partner4.png" alt="image">
                </a>
            </div>

            <div class="creative-partner-item">
                <a href="#">
                    <img src="assets/img/home-seven/partner/partner5.png" alt="image">
                </a>
            </div>

            <div class="creative-partner-item">
                <a href="#">
                    <img src="assets/img/home-seven/partner/partner6.png" alt="image">
                </a>
            </div>
        </div>
    </div>
</div>
<!-- End Creative Partner Area -->

<!-- Start Creative Pricing Area -->
<section class="uk-creative-pricing creative-pricing-area pt-100 pb-70">
    <div class="uk-container">
        <div class="section-title-with-big-text">
            <div class="big-text">Prices</div>
            <span>OUR PRICES</span>
            <h2>Our Flexible Pricing Plan</h2>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            <div class="uk-item">
                <div class="creative-pricing-box">
                    <div class="pricing-header">
                        <h3>Starter Plan</h3>
                    </div>

                    <div class="price">
                        $49.99 <span>Per Month</span>
                    </div>

                    <ul class="features">
                        <li><i class='bx bx-check'></i> 15GB Bandwidth Internet</li>
                        <li><i class='bx bx-check'></i> Business & Financ Analysing</li>
                        <li><i class='bx bx-check'></i> 30 Social Media Reviews</li>
                        <li><i class='bx bx-check'></i> Customer Managemet</li>
                        <li><i class='bx bx-check'></i> Weekly Reports</li>
                        <li><i class='bx bx-check'></i> 24/7 Support</li>
                    </ul>

                    <a href="#" class="uk-button uk-button-default">Choose Plan</a>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-pricing-box">
                    <div class="pricing-header">
                        <h3>Advance Plan</h3>
                    </div>

                    <div class="price">
                        $59.99 <span>Per Month</span>
                    </div>

                    <ul class="features">
                        <li><i class='bx bx-check'></i> 15GB Bandwidth Internet</li>
                        <li><i class='bx bx-check'></i> Business & Financ Analysing</li>
                        <li><i class='bx bx-check'></i> 30 Social Media Reviews</li>
                        <li><i class='bx bx-check'></i> Customer Managemet</li>
                        <li><i class='bx bx-check'></i> Weekly Reports</li>
                        <li><i class='bx bx-check'></i> 24/7 Support</li>
                    </ul>

                    <a href="#" class="uk-button uk-button-default">Choose Plan</a>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-pricing-box">
                    <div class="pricing-header">
                        <h3>Premium Plan</h3>
                    </div>

                    <div class="price">
                        $99.99 <span>Per Month</span>
                    </div>

                    <ul class="features">
                        <li><i class='bx bx-check'></i> 15GB Bandwidth Internet</li>
                        <li><i class='bx bx-check'></i> Business & Financ Analysing</li>
                        <li><i class='bx bx-check'></i> 30 Social Media Reviews</li>
                        <li><i class='bx bx-check'></i> Customer Managemet</li>
                        <li><i class='bx bx-check'></i> Weekly Reports</li>
                        <li><i class='bx bx-check'></i> 24/7 Support</li>
                    </ul>

                    <a href="#" class="uk-button uk-button-default">Choose Plan</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Creative Pricing Area -->

<!-- Start Creative Team Area -->
<section class="uk-creative-team creative-team-area pb-70">
    <div class="uk-container">
        <div class="section-title-with-big-text">
            <div class="big-text">Our Team</div>
            <span>MEET OUR EXPERTS</span>
            <h2>Meet Our Experts Team Member</h2>
        </div>
        
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-4@m uk-child-width-1-2@s">
            <div class="item">
                <div class="creative-team-box">
                    <div class="team-image">
                        <img src="assets/img/home-seven/team/team1.jpg" alt="image">

                        <ul class="team-social">
                            <li><a href="#"><i class="flaticon-logo"></i></a></li>
                            <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                            <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
                        </ul>
                    </div>
                    <div class="team-content">
                        <h3>Josh Buttler</h3>
                        <span>Photographer</span>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="creative-team-box">
                    <div class="team-image style-two">
                        <img src="assets/img/home-seven/team/team2.jpg" alt="image">

                        <ul class="team-social">
                            <li><a href="#"><i class="flaticon-logo"></i></a></li>
                            <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                            <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
                        </ul>
                    </div>
                    <div class="team-content">
                        <h3>Steve Eva</h3>
                        <span>Designer</span>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="creative-team-box">
                    <div class="team-image">
                        <img src="assets/img/home-seven/team/team3.jpg" alt="image">

                        <ul class="team-social">
                            <li><a href="#"><i class="flaticon-logo"></i></a></li>
                            <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                            <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
                        </ul>
                    </div>
                    <div class="team-content">
                        <h3>Brand Camus</h3>
                        <span>Web Developer</span>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="creative-team-box">
                    <div class="team-image style-two">
                        <img src="assets/img/home-seven/team/team4.jpg" alt="image">

                        <ul class="team-social">
                            <li><a href="#"><i class="flaticon-logo"></i></a></li>
                            <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                            <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
                        </ul>
                    </div>
                    <div class="team-content">
                        <h3>Olivia Smith</h3>
                        <span>Front-End Developer</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Creative Team Area -->

<!-- Start Creative News Area -->
<section class="uk-creative-news creative-news-area pt-100 pb-100">
    <div class="uk-container">
        <div class="section-title-with-big-text">
            <div class="big-text">News</div>
            <span>Latest News</span>
            <h2>Read Our Latest News</h2>
        </div>

        <div class="creative-news-slides owl-carousel owl-theme">
            <div class="creative-news-card">
                <ul class="meta">
                    <li><a href="#">Web</a></li>
                    <li>02-02-2022</li>
                </ul>
                <h3>
                    <a href="single-blog.html">University Admissions Could Face Emergency Controls</a>
                </h3>
                <div class="info">
                    <img src="assets/img/client1.png" alt="image">

                    <div class="title">
                        <h4>By <a href="#">Burnett</a></h4>
                    </div>
                </div>
            </div>

            <div class="creative-news-card">
                <ul class="meta">
                    <li><a href="#">Development</a></li>
                    <li>02-02-2022</li>
                </ul>
                <h3>
                    <a href="single-blog.html">How To Create A ReactJS Image Lightbox Gallery?</a>
                </h3>
                <div class="info">
                    <img src="assets/img/client2.png" alt="image">

                    <div class="title">
                        <h4>By <a href="#">Jimmie</a></h4>
                    </div>
                </div>
            </div>

            <div class="creative-news-card">
                <ul class="meta">
                    <li><a href="#">Design</a></li>
                    <li>02-02-2022</li>
                </ul>
                <h3>
                    <a href="single-blog.html">Hide WooCommerce Products From Specific Categories</a>
                </h3>
                <div class="info">
                    <img src="assets/img/client3.png" alt="image">

                    <div class="title">
                        <h4>By <a href="#">Rodriguez</a></h4>
                    </div>
                </div>
            </div>

            <div class="creative-news-card">
                <ul class="meta">
                    <li><a href="#">Web</a></li>
                    <li>02-02-2022</li>
                </ul>
                <h3>
                    <a href="single-blog.html">University Admissions Could Face Emergency Controls</a>
                </h3>
                <div class="info">
                    <img src="assets/img/client1.png" alt="image">

                    <div class="title">
                        <h4>By <a href="#">Burnett</a></h4>
                    </div>
                </div>
            </div>

            <div class="creative-news-card">
                <ul class="meta">
                    <li><a href="#">Development</a></li>
                    <li>02-02-2022</li>
                </ul>
                <h3>
                    <a href="single-blog.html">How To Create A ReactJS Image Lightbox Gallery?</a>
                </h3>
                <div class="info">
                    <img src="assets/img/client2.png" alt="image">

                    <div class="title">
                        <h4>By <a href="#">Jimmie</a></h4>
                    </div>
                </div>
            </div>

            <div class="creative-news-card">
                <ul class="meta">
                    <li><a href="#">Design</a></li>
                    <li>02-02-2022</li>
                </ul>
                <h3>
                    <a href="single-blog.html">Hide WooCommerce Products From Specific Categories</a>
                </h3>
                <div class="info">
                    <img src="assets/img/client3.png" alt="image">

                    <div class="title">
                        <h4>By <a href="#">Rodriguez</a></h4>
                    </div>
                </div>
            </div>

            <div class="creative-news-card">
                <ul class="meta">
                    <li><a href="#">Web</a></li>
                    <li>02-02-2022</li>
                </ul>
                <h3>
                    <a href="single-blog.html">University Admissions Could Face Emergency Controls</a>
                </h3>
                <div class="info">
                    <img src="assets/img/client1.png" alt="image">

                    <div class="title">
                        <h4>By <a href="#">Burnett</a></h4>
                    </div>
                </div>
            </div>

            <div class="creative-news-card">
                <ul class="meta">
                    <li><a href="#">Development</a></li>
                    <li>02-02-2022</li>
                </ul>
                <h3>
                    <a href="single-blog.html">How To Create A ReactJS Image Lightbox Gallery?</a>
                </h3>
                <div class="info">
                    <img src="assets/img/client2.png" alt="image">

                    <div class="title">
                        <h4>By <a href="#">Jimmie</a></h4>
                    </div>
                </div>
            </div>

            <div class="creative-news-card">
                <ul class="meta">
                    <li><a href="#">Design</a></li>
                    <li>02-02-2022</li>
                </ul>
                <h3>
                    <a href="single-blog.html">Hide WooCommerce Products From Specific Categories</a>
                </h3>
                <div class="info">
                    <img src="assets/img/client3.png" alt="image">

                    <div class="title">
                        <h4>By <a href="#">Rodriguez</a></h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Creative News Area -->

<!-- Start Creative Contact Area -->
<div class="uk-creative-contact creative-contact-area ptb-100">
    <div class="uk-container">
        <div class="section-title-with-big-text">
            <div class="big-text">Contact Us</div>
            <span>LET’S TALK</span>
            <h2>Want To Work With Our Team?</h2>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="creative-contact-image">
                    <img src="assets/img/home-seven/contact.jpg" alt="image">

                    <div class="info-content">
                        <h3>Our Office</h3>
                        <span>352/71 Second Street King Street Kingston United Kingdom</span>
                    </div>
                </div>
            </div>

            <div class="item uk-flex-middle">
                <form class="creative-contact-form">
                    <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
                        <div class="item uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Name</label>
                            <input type="text" class="uk-input" placeholder="Eugene trask">
                        </div>

                        <div class="item uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Email</label>
                            <input type="email" class="uk-input" placeholder="trask@gmail.com">
                        </div>

                        <div class="item uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Phone</label>
                            <input type="text" class="uk-input" placeholder="+1-541-754-3010">
                        </div>

                        <div class="item uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Subject</label>
                            <input type="text" class="uk-input" placeholder="Your subject here">
                        </div>
                    </div>

                    <div class="item">
                        <label class="uk-form-label" for="form-stacked-text">Your message</label>
                        <textarea class="uk-textarea" cols="30" rows="4" placeholder="Write your message here..."></textarea>
                    </div>
                    
                    <div class="checkbox-boxes">
                        <label><input class="uk-checkbox" type="checkbox"> By Checking This, You Agree To Our <a href="#">Terms</a> And <a href="#">Privacy Policy</a></label>
                    </div>

                    <button type="submit" class="uk-button uk-button-default">Submit Message</button>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- End Creative Contact Area -->