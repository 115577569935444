<!-- Start Footer Area -->
<footer class="footer-area {{ location == '/' ? '' : 'uk-dark' }} uk-footer">
    <div class="uk-container">
        <div
            class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-4@m uk-child-width-1-2@s"
        >
            <div class="item">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a routerLink="/">
                            <!-- <img src="../../../../assets/img/logo/mobile-logo.png" alt="logo" width="20%"> -->
                            <img
                                src="../../../../assets/img/logo/web-logo.png"
                                alt="logo"
                            />
                        </a>
                    </div>
                    <p>
                        Zest India - An ISO 9001:2015 certified global software
                        services company that focuses on Mobile and Web
                        Application Development.
                    </p>
                </div>
            </div>

            <div class="item">
                <div class="single-footer-widget">
                    <h3>Pune</h3>
                    <div class="location">
                        <p>
                            <i
                                class="fa fa-map-marker me-2"
                                aria-hidden="true"
                            ></i>
                            Banglore Bypass, Spacia,<br />
                            Sr.No:130/4/1B, Bhumkar Rd, <br />
                            near Jaguar Showroom, Wakad, <br />
                            Mumbai, pune, Maharashtra 411057
                        </p>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="single-footer-widget">
                    <h3>London</h3>
                    <div class="location">
                        <p>
                            <i
                                class="fa fa-map-marker me-2"
                                aria-hidden="true"
                            ></i>
                            55 Norfolk Road, <br />
                            IG3 8,London, <br />
                            United Kingdom
                        </p>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="single-footer-widget">
                    <h3>Singapore</h3>
                    <div class="location">
                        <p>
                            <i
                                class="fa fa-map-marker me-2"
                                aria-hidden="true"
                            ></i>
                            Blk 296,Yishun Street 20, <br />
                            #10-09 Singapore-760296
                        </p>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="single-footer-widget">
                    <h3>Contact</h3>
                    <ul class="contact-info">
                        <li>
                            <a href="tel:+919665044698"
                                ><i class="fa fa-phone me-2"></i> +91 966 504
                                4698</a
                            >
                        </li>
                        <li>
                            <a href="mailto:info@zestindiait.com"
                                ><i
                                    class="fa fa-envelope me-2"
                                    aria-hidden="true"
                                ></i>
                                info@zestindiait.com
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="social__icons">
            <ul class="social">
                <li>
                    <a
                        href="https://www.linkedin.com/company/97881074/admin/feed/posts/"
                        target="_blank"
                        ><i class="flaticon-linkedin"></i
                    ></a>
                </li>
                <li>
                    <a href="#" target="_blank"
                        ><i class="flaticon-logo"></i
                    ></a>
                </li>
                <li>
                    <a href="#" target="_blank"
                        ><i class="flaticon-twitter"></i
                    ></a>
                </li>
                <li>
                    <a href="#" target="_blank"
                        ><i class="flaticon-logo-1"></i
                    ></a>
                </li>
            </ul>
        </div>

        <div class="copyright-area">
            <div
                class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-2@s"
            >
                <div class="item">
                    <p>
                        © 2025 All Rights Reserved By
                        <span class="owner">Zest India IT Services.</span>
                    </p>
                </div>

                <div class="item">
                    <ul>
                        <li><a href="#">Terms & Conditions</a></li>
                        <li><a href="#">Privacy Policy</a></li>
                    </ul>
                </div>
            </div>
            <div class="go-top"><i class="flaticon-chevron"></i></div>
        </div>
    </div>

    <div class="br-line"></div>
    <div class="footer-shape1">
        <img src="assets/img/footer-shape1.png" alt="shape" />
    </div>
    <div class="footer-shape2">
        <img src="assets/img/footer-shape2.png" alt="shape" />
    </div>
</footer>
<!-- End Footer Area -->
